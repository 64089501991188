@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;300;400;500;700&display=swap');

@import "~bootstrap/scss/functions";

@import "variables";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/list-group";

@import "~bootstrap/scss/close";
@import "~bootstrap/scss/placeholders";

* {
  outline: none !important;
}

html {
  height: 0px !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.theme-hospital {
  --su-primary : #{$primary};
  --su-primary-hover : #{$primary-hover};
  --su-primary-1 : #{$primary-1};
  --su-primary-2 : #{$primary-2};
}

.theme-agent {
  --su-primary : #{$primary-agent};
  --su-primary-hover : #{$primary-agent-hover};
  --su-primary-rgb : #{$primary-agent-rgp};
  --su-text-opacity : #{$primary-agent-hover-rgp};
  --su-primary-1 : #{$primary-agent-1};
  --su-primary-2 : #{$primary-agent-2};
}

.theme-lab {
  --su-primary : #{$primary-lab};
  --su-primary-hover : #{$primary-lab-hover};
  --su-primary-rgb : #{$primary-lab-rgp};
  --su-text-opacity : #{$primary-lab-hover-rgp};
  --su-primary-1 : #{$primary-lab-1};
  --su-primary-2 : #{$primary-lab-2};
}

.theme-pharmacy {
  --su-primary : #{$primary-pharmacy};
  --su-primary-hover : #{$primary-pharmacy-hover};
  --su-primary-rgb : #{$primary-pharmacy-rgp};
  --su-text-opacity : #{$primary-pharmacy-hover-rgp};
  --su-primary-1 : #{$primary-pharmacy-1};
  --su-primary-2 : #{$primary-pharmacy-2};
}

a,
.nav-link {
  color: var(--su-primary);

  &:hover {
    color: var(--su-primary-hover);
  }
}

.border-primary {
  border-color: var(--su-primary) !important;
}

.tabstyle {
  background-image: linear-gradient(#0f695a, #95e4d7);
  color: #ffffff;
  border: solid;
  text-align: center;
  font-size: 40;
  font-style: bold;
  font-family: Roboto;

}

//header button web view
.header-button-style {
  background-color: #04204E !important;
  height: 3rem;
  font-weight: 800;
  bottom: 10rem;
  left: 8rem;
}

.header-title {
  position: relative;
  top: 2rem;
  color: white;
  text-align: center;
}

.header-sub-title {
  font-size: 20px;
  font-style: italic;
  position: relative;
  color: white;
  text-align: center !important;
}

.banner-content-box {
  background-color: rgba(0, 0, 0, 0.45) !important;
  left: 30px !important;
  position: absolute !important;
  bottom: 20px !important;
  border-radius: 10px !important;
  text-align: center !important;
}

.banner-style {
  width: 100%;
  height: 200px !important;
  object-fit: cover;
}

.centeredHeaderText {
  position: absolute;
  // transform: translate(0%, 50%);
}

.banner-row-mr-bm {
  margin-bottom: -4%;
}

.dNone {
  display: block !important;
}

.dBlock {
  display: none !important;
}

.headerBannerText {
  color: white;
  background: rgba(3, 3, 5, 0.4);
  border-radius: 20px;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}

.fw300 {
  font-weight: 300;
}

//header button tab view
@media screen and (max-width: 768px) {
  .header-button-style {
    background-color: #04204E !important;
    height: 3rem;
    font-weight: 800;
    bottom: 5rem;
    left: 6rem;
  }

  // .banner-style {
  //   width: 100%;
  //   height: 200px !important;
  // }

  .header-title {
    position: relative;
    top: 1rem;
    color: white;
    text-align: center;
  }

  .header-sub-title {
    font-size: 11px;
    position: relative;
    top: -19%;
    color: white;
    text-align: center !important;
    font-style: italic;
  }

  .dNone {
    display: none !important;
  }

  .dBlock {
    display: block !important;
  }

  .dCenter {
    text-align: center;
  }
}

//header button mobile view
@media screen and (max-width: 576px) {
  .g-recaptcha {
    -webkit-transform: scale(0.77);
    transform: scale(0.77);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .header-button-style {
    background-color: #04204E !important;
    height: 3rem;
    font-weight: 800;
    top: -4rem;
    // bottom: 8rem;
    left: 2rem;
  }

  // .banner-content-box {
  //   background-color: rgba(0, 0, 0, 0.45);
  //   left: 2% !important;
  //   position: relative;
  //   top: -47% !important;
  //   border-radius: 10px !important;
  //   text-align: center !important;
  // }

  // .banner-style {
  //   width: 100%;
  //   height: 200px !important;
  // }

  .banner-row-mr-bm {
    margin-bottom: -15%;
  }

  .header-title {
    position: relative;
    top: 1rem;
    color: white;
    text-align: center;
  }

  .header-sub-title {
    font-size: 11px;
    position: relative;
    top: -19%;
    color: white;
    text-align: center !important;
    font-style: italic;
  }
}

// .banner-style {
//   width: 100%;
//   height: 80%;
// }

.button-style {
  width: 275px;
  height: 100px;
  background-image: linear-gradient(#00537E, #3AA17E);
  color: #ffffff;
  border: solid;
  text-align: center;
  font-size: 40;
  font-style: bold;
  font-family: Roboto;
}

.button-style-Labprocess {
  width: 275px;
  height: 100px;
  background-image: linear-gradient(#0f695a, #95e4d7);
  color: #ffffff;
  border: solid;
  text-align: center;
  font-size: 40;
  font-style: bold;
  font-family: Roboto;


}

.button-style-forcompany {
  width: 275px;
  height: 100px;
  background-image: linear-gradient(#E66941, #BA529D);
  text-align: center;
  color: #ffffff;
  border: solid;
  text-align: center;
  font-size: 40;
  font-style: bold;
  font-family: Roboto;

}

.button {
  width: 175px;
  height: 50px;
  background-image: linear-gradient(#129a7f, #129a7f);
  color: white;
  border: solid;
  text-align: center;
}

/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 25%;
  padding: 10px;
  //height: 300px; /* Should be removed. Only for demonstration */
}

.columnwidth {
  float: left;
  width: 55%;
  padding: 10px;
  //height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

hr.line2 {
  border-top: 1px dashed rgb(14, 13, 13);
  height: 1px;
}

.linepadding {
  padding-left: 1px;
  padding-top: 25px;
  // padding-right: 1px;
  // margin-right: -16px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 3.5px 14px !important;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 37%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  cursor: pointer;
  background: #ededed;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  padding: 0.2rem 0.6rem;
}

.btn-linear-home {
  transform-origin: bottom right;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
  margin-bottom: -0.5rem;
  font-weight: 600;
  text-align: center;
  width: 36rem;
}

.btn-linear {
  transform: skew(-20deg);
  transform-origin: bottom right;
  width: 25rem;
  height: 3.5rem;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
  margin-bottom: .5rem;
  padding-top: 12px;
}

.btn-linear span {
  transform: skew(20deg);
  color: black;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

.txt-align-justify {
  text-align: justify;
}

body>iframe {
  pointer-events: none;
}

.bgLightMuted {
  background-color: #c5c5c5 !important;
  color: gray !important;
}

.labelBgGradient {
  font-weight: 500;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
}

.btn-linear-staff {
  // transform: skew(-20deg);
  transform-origin: bottom right;
  width: 30rem;
  height: 3.5rem;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
  margin-bottom: 1.5rem;
  padding-top: 12px;
  margin-left: 27%;
  margin-top: 15px;
}

.btn-linear-staff span {
  // transform: skew(20deg);
  color: black;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

.btn-linear-TamiThaai {
  transform: skew(-20deg);
  transform-origin: bottom right;
  width: 33rem;
  height: 3.5rem;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
  margin-bottom: 1.5rem;
  padding-top: 12px;
  margin-left: 25%;
  margin-top: 15px;
}

.btn-linear-TamiThaai span {
  transform: skew(20deg);
  color: black;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

.btn-linear-course {
  transform: skew(-20deg);
  transform-origin: bottom right;
  width: 25rem;
  height: 3.5rem;
  background: linear-gradient(#ECC440, #FFFA8A, #DDAC17, #FFFF95);
  margin-bottom: .5rem;
  padding-top: 12px;
}

.btn-linear-course span {
  transform: skew(20deg);
  color: black;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

@media (max-width: 768px) {
  .btn-linear {
    width: 100%;
    height: 4.5rem;

  }

  .btn-linear-staff {
    margin-left: 0%;
    width: 100%;
    height: 4.5rem;
  }

  .btn-linear-staff span {
    font-size: 14px;
  }

  .btn-linear-TamiThaai {
    margin-left: 0%;
    width: unset;
    height: 4.5rem;
    transform-origin: unset;
  }

  .btn-linear-TamiThaai span {
    font-size: 14px;
  }

  .btn-linear-course {
    margin-left: 0%;
    width: 100%;
  }

  .btn-linear-course span {
    font-size: 14px;
  }

  .banner-row-mr-bm {
    margin-bottom: -15%;
  }
}

// @media (max-width:991px) {
//   .banner-content-box {
//     height: 36% !important;
//     top: -40% !important;
//   }
// }

.scroll {
  overflow-y: scroll;
  height: 36.3em;
  margin: 10px 5px;
  padding: 5px;
  // text-align: justify;
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px #EECA49;
  border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 8px #ba9e3a;
}

.carousel-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}