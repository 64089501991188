$body-bg                    : #ffffff;
$primary                    : #0E4A42;
$primary-hover              : #00c8a7;
$primary-1                  : $primary;
$primary-2                  : $primary-hover;
$secondary                  : #941c39;

$primary-agent              : #084fba;
$primary-agent-rgp          : 8, 79, 186;
$primary-agent-hover        : #063f95;
$primary-agent-hover-rgp    : 6, 63, 149;
$primary-agent-1            : #0f7ba7;
$primary-agent-2            : #0bd0d5;

$primary-lab                : #129a7f;
$primary-lab-rgp            : 18, 154, 127;
$primary-lab-hover          : #68cf86;
$primary-lab-hover-rgp      : 104, 207, 134;
$primary-lab-1              : $primary-lab;
$primary-lab-2              : $primary-lab-hover;

$primary-pharmacy           : #00C7A7;
$primary-pharmacy-rgp       : 18, 154, 127;
$primary-pharmacy-hover     : #01AEC1;
$primary-pharmacy-hover-rgp : 104, 207, 134;
$primary-pharmacy-1         :$primary-pharmacy;
$primary-pharmacy-2         :$primary-pharmacy-hover;

$variable-prefix            : su- !default;
$font-family-sans-serif     : 'Rubik' !default;
$enable-negative-margins    : true !default;
